import React from 'react';

import { Typography, Grid, Theme, Button } from '@material-ui/core';

import { Helmet } from 'react-helmet';

import { useHistory, useLocation } from 'react-router-dom';

import {
  ShowCase,
  Story,
  OfferCard,
  LoginForm,
  SignupForm,
} from '../../../components';
import CoronaImg from '../../../assets/svg/coroana.svg';
import WomanImg from '../../../assets/svg/womenWithLab.svg';
import TeamIcon1 from '../../../assets/ourTeam_1st.png';
import TeamIcon2 from '../../../assets/ourTeam_2d.png';
import TeamIcon3 from '../../../assets/ourTeam_3d.png';
import Vaccine from '../../../assets/vaccine.png';
import urgentVsEmergencyImg from '../../../assets/svg/urgentVsEmergency.svg';

import { makeStyles, createStyles } from '@material-ui/core';
import Banner from '../../../components/Banner';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ourTeamSection: {
      padding: '7rem 0',
      [theme.breakpoints.down('xs')]: {
        padding: '1rem',
        marginTop: '2rem',
      },
    },
    MUILimition: {
      padding: '40px',
    },
    title: {
      textAlign: 'center',
    },
    centerText: {
      textAlign: 'center',
    },
    ourTeamCardItem: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '2rem',
      },
    },
    ourTeamCardTitle: {
      fontSize: '21px',
      color: theme.palette.primary.dark,
    },
    ourTeamCardDesc: {
      fontSize: '16px',
      color: theme.palette.primary.dark,
    },
    titleWrapper: {
      marginBottom: '6rem',
    },
    choseClincSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      textAlign: 'center',
      alignItems: 'center',
      height: '570px',
      width: '100%',
      background: `url(${urgentVsEmergencyImg}) no-repeat center center/cover`,
    },
    choseClincSectionDesc: {
      lineHeight: '2',
      color: '#FFFFFF',
    },
      banner:{
          margin:'0 auto',
          maxWidth:'70%',
          marginBottom:'60px',
          [theme.breakpoints.down('xs')]: {
              maxWidth:'95%',
          },
      }
  }),
);
const Home: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const handleClick = () => history.push('/urgent-care-vs-emergency-room');
  return (
    <main>
      <Helmet>
        <title>
          Kenosha Urgent Care | Walk-In Clinic | Fast, Affordable Health Care
        </title>
        <meta
          name="description"
          content="Book your appointment online. Fast and affordable. Call us: 262-925-0535 | Visit us today: 6430 Green Bay Road, Kenosha WI 53142"
        />

        <link rel="canonical" href="/" />
      </Helmet>

      {pathname === '/' && <><ShowCase /><Banner /> </>}
      {pathname === '/login' && <LoginForm />}
      {pathname === '/signup' && <SignupForm />}

        <div className={classes.banner}>
            {/* <img src={Vaccine} alt={'vaccine'} style={{width:'100%', height:'auto'}}/> */}
        </div>
      {/* <Story
        imgLink={CoronaImg}
        title="Coronavirus Update & Information"
        descriptionArr={[
          `— Covid testing done for symptomatic patients only- fever, cough, body aches, loss of taste smell, etc… \n\n`,
          `— We are not a testing center—we are an urgent care. An office visit is required for ALL PATINETS getting covid testing (physician evaluation). The purpose of this is to evaluate for any potential complications caused by covid/severity of illness and fitness for work ...`,
        ]}
        link="/corona-update"
      /> */}
      <section className={classes.ourTeamSection}>
        <Grid container>
          <Grid className={classes.titleWrapper} item xs={12}>
            <Typography className={classes.title} component="h2" variant="h2">
              Our Team
            </Typography>
            <Typography className={classes.title} component="p" variant="h6">
              You can click on one item to read all the details related to it.
            </Typography>
          </Grid>

          <Grid item container xs={12} justify="space-around">
            <OfferCard
              className={classes.ourTeamCardItem}
              title="When to find us"
              description="We provide walk-in care Mondays through Saturdays. Our
                      convenient hours fit most personal so you and your family
                      can get the efficient and effective care you need."
              imgLink={TeamIcon1}
            />
            <OfferCard
              className={classes.ourTeamCardItem}
              title="What to expect"
              description="We provide care that fits your needs, including urgent care . Learn about our urgent care and occupational services before you visit the clinic."
              imgLink={TeamIcon2}
            />
            <OfferCard
              className={classes.ourTeamCardItem}
              title="Discover our health team"
              description="Care comes in all shapes and sizes. The providers at Kenosha Urgicare work from a shared vision of patient care that believes in listening to our patients, working efficiently, and following through."
              imgLink={TeamIcon3}
            />
          </Grid>
        </Grid>
      </section>
      <section className={classes.choseClincSection}>
        <Typography color="primary" component="p" variant="h2">
          Urgent Care vs. the Emergency Room
        </Typography>
        <Typography
          className={classes.choseClincSectionDesc}
          component="p"
          variant="h6"
        >
          When should you choose Kenosha Urgicare instead of the emergency room
          <br />
          for an urgent need? Read about the differences in care models, and how
          to
          <br />
          make an informed decision.
        </Typography>
        <Button
          onClick={handleClick}
          size="medium"
          variant="contained"
          color="primary"
        >
          Learn More
        </Button>
      </section>
    </main>
  );
};

export default Home;
