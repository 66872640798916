import React from 'react'
import { Box, Container , Typography } from '@material-ui/core'
import {makeStyles ,  createStyles, Theme } from '@material-ui/core/styles'
import { BiFontColor } from 'react-icons/bi'
/* import bannerImage from '../../assets/bannerImage.jpg' */
const useStyles = makeStyles((theme: Theme) => (createStyles({
    bannerContainer:{
    backgroundColor: '#ad2320',    
        marginBottom:'3rem',
        color: 'white',
       
    },

     bannerContent:{
        backgroundColor: 'black',
        height: '70vh',
        display: 'flex',
        alignItems: 'center',
        
        justifyContent: 'center',
        opacity: '.7'
    
    
    }, 
    bannerText:{
width: '70%', 
marginLeft:'auto',
marginRight:'auto',
fontWeight: 'bold',
    }

})))

const Banner = () => {

    const classes = useStyles() ; 
    return (
        <div className={classes.bannerContainer}>
          <Container maxWidth="xl" >
    <Box py={8} textAlign="center" alignItems='center' >
     
      
      <Typography variant="h2" component="h2" className={classes.bannerText}>Longer wait times than normal & closed on Sundays.</Typography><br/>
      <Typography variant='h6' className={classes.bannerText}> May stop taking patients before closing time (up to 1-1.5 hrs) due to high volumes of patients. Online appointments are preferred and will take a limited number of walk-ins.</Typography>
      
    </Box>
  </Container>
        </div>
    )
}

export default Banner
